import axiosInstance from "../api";
const token = localStorage.getItem("token");

export const addGiftCard = async (giftCardData) => {
  const data = new FormData();
  data.append("heading", giftCardData.heading);
  data.append("discription", giftCardData.discription);
  data.append("image", JSON.stringify(giftCardData.image));
  data.append("type", JSON.stringify(giftCardData.categoryField));
  data.append("location", JSON.stringify(giftCardData.locationField));
  data.append("value", JSON.stringify(giftCardData.valueField));

  const response = await axiosInstance.request({
    url: "adddigitalgift",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const updateGiftCard = async (giftCardData) => {
  const data = new FormData();
  data.append("id", giftCardData.id);
  data.append("heading", giftCardData.heading);
  data.append("subheading", giftCardData.subheading);
  data.append("discription", giftCardData.discription);
  data.append("image", JSON.stringify(giftCardData.image));
  data.append("type", JSON.stringify(giftCardData.categoryField));
  data.append("location", JSON.stringify(giftCardData.locationField));
  data.append("value", JSON.stringify(giftCardData.valueField));

  const response = await axiosInstance.request({
    url: "updatedigitalgift",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const addGiftImage = async (giftImgData) => {
  const data = new FormData();
  data.append("image", giftImgData.image);
  const response = await axiosInstance.request({
    url: "addimages",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const addCelebrationImage = async (giftImgData) => {
  const data = new FormData();
  data.append("image", giftImgData.image);
  const response = await axiosInstance.request({
    url: "addimagesclebrations",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const addCelebrationBannerImage = async (giftImgData) => {
  const data = new FormData();
  data.append("image", giftImgData.image);
  const response = await axiosInstance.request({
    url: "addclebrationsBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const addCelebrationData = async (addCelebrationPayload) => {
  const data = new FormData();
  data.append("image", JSON.stringify(addCelebrationPayload.image));
  data.append("title", addCelebrationPayload.title);
  data.append("description", addCelebrationPayload.description);
  data.append("location", addCelebrationPayload.location);
  data.append("meta_title", addCelebrationPayload.metaTitle);
  data.append("meta_description", addCelebrationPayload.metaDescription);
  data.append("meta_tag", addCelebrationPayload.metaTag);
  const response = await axiosInstance.request({
    url: "addCelebrationDetails",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updateCelebrationData = async (addCelebrationPayload) => {
  const data = new FormData();

  data.append("id", addCelebrationPayload.id);
  data.append("image", JSON.stringify(addCelebrationPayload.image));
  data.append("title", addCelebrationPayload.title);
  data.append("description", addCelebrationPayload.description);
  data.append("location", addCelebrationPayload.location);
  data.append("meta_title", addCelebrationPayload.metaTitle);
  data.append("meta_description", addCelebrationPayload.metaDescription);
  data.append("meta_tag", addCelebrationPayload.metaTag);
  const response = await axiosInstance.request({
    url: "updateCelebrationDetails",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteImage = async (deleteimages) => {

  const response = await axiosInstance.request({
    url: `deleteMedia/${deleteimages.image}`,
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteRestaurantdetails = async (id) => {

  const response = await axiosInstance.request({
    url: `deleterestaurantItems/${id}`,
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showGiftList = async () => {
  const response = await axiosInstance.request({
    url: "showdigitalgift",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const updatePrivacyNotice = async (privacyNoticeData, id) => {
  const data = new FormData();
  data.append("text", privacyNoticeData.discription);
  data.append("id", id);

  const response = await axiosInstance.request({
    url: "updateprivacy",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const editGiftCard = async (id) => {
  const response = await axiosInstance.request({
    url: `showdigitalgiftid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteGiftCard = async (id) => {
  const response = await axiosInstance.request({
    url: `deletedigitalgift/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};




export const showGiftApplierList = async () => {
  const response = await axiosInstance.request({
    url: "giftUserDetails",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const viewApplierDetail = async (id) => {
  const response = await axiosInstance.request({
    url: `giftUserDetailsid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const showCelebrationsList = async () => {
  const response = await axiosInstance.request({
    url: "allCelebrationDetails",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const findCelebrations = async (id) => {
  const response = await axiosInstance.request({
    url: `findCelebrationDetails/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteCelebrationlist = async (id) => {
  const response = await axiosInstance.request({
    url: `deleteCelebrationDetails/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const celebrationlandingList = async () => {
  const response = await axiosInstance.request({
    url: "showlistcelebrationsimages",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteCelebrationimages = async (id) => {
  const response = await axiosInstance.request({
    url: `deletecelebrationsimages/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const AddRestaurantName = async (restaurantDataData) => {
  const data = new FormData();
  data.append("location", restaurantDataData.location);
  data.append("name", restaurantDataData.name);

  const response = await axiosInstance.request({
    url: "addrestaurantname",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const showRestaurantName = async () => {
  const response = await axiosInstance.request({
    url: "showrestaurantname",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showRestaurantBanner = async () => {
  const response = await axiosInstance.request({
    url: "showrestaurantBanner",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const deleteRestaurantName = async (id) => {

  const response = await axiosInstance.request({
    url: `deleterestaurantName/${id}`,
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const AddRestaurantBanner = async (restaurantData) => {
  const data = new FormData();
  data.append("image", restaurantData.image);
  data.append("description", restaurantData.description);
  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);
  const response = await axiosInstance.request({
    url: "addrestaurantBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const UpdateRestaurantBanner = async (restaurantData) => {
  const data = new FormData();
  data.append("id", restaurantData.id);
  data.append("image", restaurantData.image);
  data.append("description", restaurantData.description);
  data.append("mainTitle", restaurantData.mainTitle);

  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);
  const response = await axiosInstance.request({
    url: "updaterestaurantBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const AddRestaurantDetails = async (restaurantData) => {

  const data = new FormData();
  data.append("other", restaurantData.other);
  data.append("restaurant", restaurantData.restaurant);
  data.append("category", restaurantData.category);
  data.append("descriptionTitle", restaurantData.descriptionTitle);
  data.append("cardImage", restaurantData.cardImage);
  data.append("banerImage", restaurantData.banerImage);
  data.append("galleryImage", JSON.stringify(restaurantData.galleryImage));
  data.append("title", restaurantData.title);
  data.append("short_description", restaurantData.short_description);
  data.append("middle_description", restaurantData.middle_description);
  data.append("description", restaurantData.description);
  data.append("phone", restaurantData.phone);
  data.append("url", restaurantData.url);
  data.append("booking", restaurantData.booking);
  data.append("indoor", restaurantData.indoor);
  data.append("outdoor", restaurantData.outdoor);
  data.append("reception", restaurantData.reception);
  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);


  const response = await axiosInstance.request({
    url: "addrestaurantItems",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const AddtravelBanner = async (tarvelData) => {
  const data = new FormData();
  data.append("image", tarvelData.image);
  data.append("description", tarvelData.description);
  data.append("meta_title", tarvelData.metaTitle);
  data.append("meta_description", tarvelData.metaDescription);
  data.append("meta_tag", tarvelData.metaTag);
  const response = await axiosInstance.request({
    url: "addtravelBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const UpdatetravelBanner = async (tarvelData) => {
  const data = new FormData();
  data.append("id", tarvelData.id);
  data.append("image", tarvelData.image);
  data.append("description", tarvelData.description);
  data.append("meta_title", tarvelData.metaTitle);
  data.append("meta_description", tarvelData.metaDescription);
  data.append("meta_tag", tarvelData.metaTag);
  const response = await axiosInstance.request({
    url: "updatetravelBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const showTravelBanner = async () => {
  const response = await axiosInstance.request({
    url: "showtarvelBanner",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const findTravelBanner = async (id) => {
  const response = await axiosInstance.request({
    url: `findtarvelBanner/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const ShowRestaurantDetails = async () => {
  const response = await axiosInstance.request({
    url: "showrestaurantItems",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const ShowRestaurantDetailsId = async (id) => {
  const response = await axiosInstance.request({
    url: `showrestaurantItemsid/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const UpdateRestaurantDetails = async (restaurantData) => {

  const data = new FormData();
  data.append("id", restaurantData.id);
  data.append("restaurant", restaurantData.restaurant);
  data.append("category", restaurantData.category);
  data.append("descriptionTitle", restaurantData.descriptionTitle);
  data.append("cardImage", restaurantData.cardImage);
  data.append("banerImage", restaurantData.banerImage);
  data.append("galleryImage", JSON.stringify(restaurantData.galleryImage));
  data.append("title", restaurantData.title);
  data.append("short_description", restaurantData.short_description);
  data.append("middle_description", restaurantData.middle_description);
  data.append("description", restaurantData.description);
  data.append("phone", restaurantData.phone);
  data.append("booking", restaurantData.booking);
  data.append("url", restaurantData.url);
  data.append("indoor", restaurantData.indoor);
  data.append("outdoor", restaurantData.outdoor);
  data.append("reception", restaurantData.reception);
  data.append("other", restaurantData.other);
  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);


  const response = await axiosInstance.request({
    url: "updaterestaurantItems",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const AddRetreatName = async (retreatData) => {
  const data = new FormData();
  data.append("location", retreatData.location);
  data.append("title", retreatData.name);

  const response = await axiosInstance.request({
    url: "addretreatCategory",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const AddExperienceName = async (retreatData) => {
  const data = new FormData();
  data.append("title", retreatData.name);

  const response = await axiosInstance.request({
    url: "addexperienceCategory",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const showExperienceList = async () => {
  const response = await axiosInstance.request({
    url: "showexperienceCategory",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showRetreatList = async () => {
  const response = await axiosInstance.request({
    url: "showretreatCategory",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showRetreatListArray = async () => {
  const response = await axiosInstance.request({
    url: "showretreatList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showCelebartionList = async () => {
  const response = await axiosInstance.request({
    url: "showcelebrationblocks",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const deleteretreatecategory = async (id) => {
  const response = await axiosInstance.request({
    url: `deleteretreatCategory/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteexperiencecategory = async (id) => {
  const response = await axiosInstance.request({
    url: `deleteexperienceCategory/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const findExperienceCategory = async (id) => {

  const response = await axiosInstance.request({
    url: `experienceCategoryid/${id}`,
    method: "GET",

    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const findRetreatCategory = async (id) => {

  const response = await axiosInstance.request({
    url: `FindretreatCategory/${id}`,
    method: "GET",

    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};


export const findRestaurantData = async (id) => {

  const response = await axiosInstance.request({
    url: `findrestaurantBanner/${id}`,
    method: "GET",

    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updateretreatCategory = async (updateRetreatdata) => {
  const data = new FormData();
  data.append("id", updateRetreatdata.id);
  data.append("title", updateRetreatdata.name);
  // data.append("location", updateRetreatdata.location);
  const response = await axiosInstance.request({
    url: 'updateretreatCategory',
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updateExperienceCategory = async (updateRetreatdata) => {
  const data = new FormData();
  data.append("id", updateRetreatdata.id);
  data.append("title", updateRetreatdata.name);
  // data.append("location", updateRetreatdata.location);
  const response = await axiosInstance.request({
    url: 'updateExperienceCategory',
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const AddRetreatBanner = async (retreatData) => {
  const data = new FormData();
  data.append("image", retreatData.image);
  data.append("description", retreatData.description);
  data.append("title", retreatData.title);
  data.append("meta_title", retreatData.metaTitle);
  data.append("meta_description", retreatData.metaDescription);
  data.append("meta_tag", retreatData.metaTag);
  const response = await axiosInstance.request({
    url: "addretreatsBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const updateRetreatBanner = async (retreatData) => {
  const data = new FormData();
  data.append("image", retreatData.image);
  data.append("description", retreatData.description);
  data.append("title", retreatData.title);
  data.append("id", retreatData.id);
  data.append("meta_title", retreatData.metaTitle);
  data.append("meta_description", retreatData.metaDescription);
  data.append("meta_tag", retreatData.metaTag);

  const response = await axiosInstance.request({
    url: "updateretreatsBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const showRetreatBanner = async () => {
  const response = await axiosInstance.request({
    url: "showretreatsBanner",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const findRetreatBanner = async (id) => {

  const response = await axiosInstance.request({
    url: `findretreatsBanner/${id}`,
    method: "GET",

    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const showRetreatName = async () => {
  const response = await axiosInstance.request({
    url: "showretreatCategory",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const CelebrationsList = async () => {
  const response = await axiosInstance.request({
    url: "showcelebrationList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const OffersList = async () => {
  const response = await axiosInstance.request({
    url: "showoffersList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const AddRetreatData= async (retreatData) => {
  const data = new FormData();
  data.append("image", retreatData.cardImage);
  data.append("booking", retreatData.booking);
  data.append("location", retreatData.location);
  data.append("bannerImage", retreatData.imageBanner);
  data.append("title", retreatData.title);
  data.append("gallery", JSON.stringify(retreatData.imageGallery));
  data.append("shortDescription", retreatData.shortdescription);
  data.append("longDescription", retreatData.detaileddescription);
  data.append("category", retreatData.retreatCategory);
  data.append("latitude", retreatData.latitude);
  data.append("longitude", retreatData.longitude);
  data.append("mapDescription", retreatData.mapDescription);
  data.append("celebration", JSON.stringify(retreatData.celebrationListArray));
  data.append("offers", JSON.stringify(retreatData.exclusiveOfferArray));
  data.append("accomodation_gallery", JSON.stringify(retreatData.imageGalleryAccomodationArray));
  data.append("dining_gallery", JSON.stringify(retreatData.imageDiningGalleryArray));
  data.append("accomodation_description", retreatData.accomodationDescription);
  data.append("dining_description", retreatData.diningDescription);
  data.append("place", retreatData.categoryPlace);
  data.append("mapframe", retreatData.maPIframe);
  data.append("dining_title", retreatData.diningTitle);
  data.append("indoor", retreatData.indoor);
  data.append("innertitle", retreatData.innerTitle);
  data.append("outdoor", retreatData.outdoor);
  data.append("reception", retreatData.reception);
  data.append("wellness_gallery", JSON.stringify(retreatData.imageWellnessArray));
  data.append("wellness_description", JSON.stringify(retreatData.wellnessDescription));
  data.append("meta_title", retreatData.metaTitle);
  data.append("meta_description", retreatData.metaDescription);
  data.append("meta_tag", retreatData.metaTag);

  const response = await axiosInstance.request({
    url: "addretreat",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const updateRetreatData= async (retreatData) => {
  const data = new FormData();
  data.append("id", retreatData.id);
  data.append("location", retreatData.location);
  data.append("booking", retreatData.booking);
  data.append("image", retreatData.cardImage);
  data.append("bannerImage", retreatData.imageBanner);
  data.append("title", retreatData.title);
  data.append("url", retreatData.url);
  data.append("innertitle", retreatData.innerTitle);
  data.append("gallery", JSON.stringify(retreatData.imageGallery));
  data.append("shortDescription", retreatData.shortdescription);
  data.append("longDescription", retreatData.detaileddescription);
  data.append("category", retreatData.retreatCategory);
  data.append("latitude", retreatData.latitude);
  data.append("longitude", retreatData.longitude);
  data.append("mapDescription", retreatData.mapDescription);
  data.append("celebration", JSON.stringify(retreatData.celebrationListArray));
  data.append("offers", JSON.stringify(retreatData.exclusiveOfferArray));
  data.append("accomodation_gallery", JSON.stringify(retreatData.imageGalleryAccomodationArray));
  data.append("dining_gallery", JSON.stringify(retreatData.imageDiningGalleryArray));
  data.append("accomodation_description", retreatData.accomodationDescription);
  data.append("dining_description", retreatData.diningDescription);
  data.append("place", retreatData.categorydata);
  data.append("dining_title", retreatData.diningTitle);
  data.append("mapframe", retreatData.maPIframe);
  data.append("indoor", retreatData.indoor);
  data.append("outdoor", retreatData.outdoor);
  data.append("reception", retreatData.reception);
  data.append("wellness_gallery", JSON.stringify(retreatData.imageWellnessArray));
  data.append("wellness_description", JSON.stringify(retreatData.wellnessDescription));
  data.append("meta_title", retreatData.metaTitle);
  data.append("meta_description", retreatData.metaDescription);
  data.append("meta_tag", retreatData.metaTag);


  const response = await axiosInstance.request({
    url: "updateretreat",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const ShowRetreatDetailsList = async () => {
  const response = await axiosInstance.request({
    url: "showretreat",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteRetreatList = async (id) => {

  const response = await axiosInstance.request({
    url: `deleteretreat/${id}`,
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteDeleteExperience = async (id) => {

  const response = await axiosInstance.request({
    url: `DeleteExperience/${id}`,
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const FindRetreatDetails = async (dataId) => {

  const response = await axiosInstance.request({
    url: `Findretreat/${dataId.id}`,
    method: "GET",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const AddRetreatRoom= async (retreatroomData) => {
  const data = new FormData();
  data.append("retreatId", retreatroomData.category);
  data.append("cardImage", retreatroomData.cardImage);
  data.append("gallery", JSON.stringify(retreatroomData.imageGallery));
  data.append("title", retreatroomData.title);
  data.append("shortDescription", retreatroomData.shortDescription);
  data.append("description", retreatroomData.description);
  data.append("longdescription", retreatroomData.longDescription);
  data.append("room_features", JSON.stringify(retreatroomData.roomFeatures));
  data.append("guest_features", JSON.stringify(retreatroomData.guestFeatures));
  data.append("banner", retreatroomData.bannerImage);
  data.append("sequence", retreatroomData.sequencedata);
  data.append("mastertitle", retreatroomData.masterRoomtitle);
  data.append("guesttitle", retreatroomData.guestRoomtitle);
  data.append("meta_title", retreatroomData.metaTitle);
  data.append("meta_description", retreatroomData.metaDescription);
  data.append("meta_tag", retreatroomData.metaTag);

  const response = await axiosInstance.request({
    url: "addretreatrooms",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const showRetreatDetailName = async () => {
  const response = await axiosInstance.request({
    url: "showretreat",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const ShowRetreatroomList = async () => {
  const response = await axiosInstance.request({
    url: "showretreatroom",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const ShowRetreatroomListForRoom = async () => {
  const response = await axiosInstance.request({
    url: "showretreatList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const ShowRestaurantListForExp = async () => {
  const response = await axiosInstance.request({
    url: "showrestaurantList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const deleteRetreatroom = async (id) => {

  const response = await axiosInstance.request({
    url: `deleteretreatroom/${id}`,
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const UpdateRetreatRoom = async (retreatroomData) => {
  const data = new FormData();
  data.append("id", retreatroomData.id);
  data.append("retreatId", retreatroomData.category);
  data.append("cardImage", retreatroomData.cardImage);
  data.append("gallery", JSON.stringify(retreatroomData.imageGallery));
  data.append("title", retreatroomData.title);
  data.append("shortDescription", retreatroomData.shortDescription);
  data.append("description", retreatroomData.description);
  data.append("longdescription", retreatroomData.longDescription);
  data.append("room_features", JSON.stringify(retreatroomData.roomFeatures));
  data.append("guest_features", JSON.stringify(retreatroomData.guestFeatures));
  data.append("banner", retreatroomData.bannerImage);
  data.append("sequence", retreatroomData.sequencedata);
  data.append("mastertitle", retreatroomData.masterRoomtitle);
  data.append("guesttitle", retreatroomData.guestRoomtitle);
  data.append("meta_title", retreatroomData.metaTitle);
  data.append("meta_description", retreatroomData.metaDescription);
  data.append("meta_tag", retreatroomData.metaTag);

  const response = await axiosInstance.request({
    url: "updateretreatrooms",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const findRetreatroom = async (id) => {

  const response = await axiosInstance.request({
    url: `Findretreatroom/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const AddCruiseLandingDetails = async (restaurantData) => {

  const data = new FormData();

  data.append("banner", restaurantData.banerImage);
  data.append("slider", JSON.stringify(restaurantData.galleryImage));
  data.append("title", restaurantData.title);
  data.append("description", restaurantData.description);
  data.append("deckPlan", restaurantData.deckPlan);
  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);


  const response = await axiosInstance.request({
    url: "addCruiseLanding",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const UpdateCruiseLandingDetails = async (restaurantData) => {

  const data = new FormData();

  data.append("id", restaurantData.id);
  data.append("banner", restaurantData.banerImage);
  data.append("slider", JSON.stringify(restaurantData.galleryImage));
  data.append("title", restaurantData.title);
  data.append("description", restaurantData.description);
  data.append("deckPlan", restaurantData.deckPlan);

  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);

  const response = await axiosInstance.request({
    url: "UpdateCruiseLanding",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const showCruiseLandingBanner = async () => {
  const response = await axiosInstance.request({
    url: 'showCruiseLanding',
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const findCruiseLandingBanner = async (id) => {
  const response = await axiosInstance.request({
    url: `findCruiseLanding/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const AddCruiseDetails = async (restaurantData) => {

  const data = new FormData();

  data.append("title", restaurantData.title);
  data.append("cardImage", restaurantData.cardImage);
  data.append("gallery", JSON.stringify(restaurantData.bannerImage));
  data.append("shortDescription", restaurantData.short_description);
  data.append("detailDescription", restaurantData.descriptionTitle);
  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);


  const response = await axiosInstance.request({
    url: "addCruise",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const showCruiseList = async () => {
  const response = await axiosInstance.request({
    url: "CruiseList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteCruise = async (id) => {

  const response = await axiosInstance.request({
    url: `deleteCruiseList/${id}`,
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteCruiseEnquiry = async (id) => {

  const response = await axiosInstance.request({
    url: `deleteCruiseEnqiuryList/${id}`,
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const findCruiseList = async (id) => {
  const response = await axiosInstance.request({
    url: `findCruiseList/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const UpdateCruiseDetails = async (restaurantData) => {

  const data = new FormData();

  data.append("id", restaurantData.id);
  data.append("title", restaurantData.title);
  data.append("cardImage", restaurantData.cardImage);
  data.append("gallery", JSON.stringify(restaurantData.bannerImage));
  data.append("shortDescription", restaurantData.short_description);
  data.append("detailDescription", restaurantData.descriptionTitle);



  const response = await axiosInstance.request({
    url: "updateCruise",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const AddCruiseEnquiryDetails = async (CruiseDetails) => {

  const data = new FormData();

  data.append("cruiseId", CruiseDetails.cruiseId);
  data.append("mapImage", CruiseDetails.mapImage);
  data.append("gallery", JSON.stringify(CruiseDetails.bannerImage));
  data.append("faq", JSON.stringify(CruiseDetails.faq));
  data.append("days", JSON.stringify(CruiseDetails.day));
  data.append("inclusions", CruiseDetails.inclusion);
  data.append("stay", CruiseDetails.stayLonger);
  data.append("package", CruiseDetails.itenary);
  data.append("firstTitle", CruiseDetails.firstTitle);
  data.append("secondTitle", CruiseDetails.secondTitle);
  data.append("title", CruiseDetails.title);
  data.append("image", CruiseDetails.packageImage);



  const response = await axiosInstance.request({
    url: "addCruiseEnqiury",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const showCruiseEnquriyList = async () => {
  const response = await axiosInstance.request({
    url: "CruiseEnqiuryList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const FindCruiseEnquriyList = async (id) => {
  const response = await axiosInstance.request({
    url: `FindCruiseEnqiuryLists/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updateCruiseEnquiryDetails = async (CruiseDetails) => {

  const data = new FormData();

  data.append("id", CruiseDetails.id);
  data.append("cruiseId", CruiseDetails.cruiseId);
  data.append("mapImage", CruiseDetails.mapImage);
  data.append("gallery", JSON.stringify(CruiseDetails.bannerImage));
  data.append("faq", JSON.stringify(CruiseDetails.faq));
  data.append("days", JSON.stringify(CruiseDetails.day));
  data.append("inclusions", CruiseDetails.inclusion);
  data.append("stay", CruiseDetails.stayLonger);
  data.append("package", CruiseDetails.itenary);
  data.append("firstTitle", CruiseDetails.firstTitle);
  data.append("secondTitle", CruiseDetails.secondTitle);
  data.append("title", CruiseDetails.title);
  data.append("image", CruiseDetails.packageImage);



  const response = await axiosInstance.request({
    url: "updateCruiseEnqiury",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const AddExperienceLandingDetails = async (restaurantData) => {

  const data = new FormData();

  data.append("banner", restaurantData.banerImage);
  data.append("description", restaurantData.description);

  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);

  const response = await axiosInstance.request({
    url: "addExperienceBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const ShowExperienceBannerList = async () => {
  const response = await axiosInstance.request({
    url: 'showExperienceBanner',
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const FindExperienceBannerList = async (id) => {
  const response = await axiosInstance.request({
    url: `FindExperienceBanner/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const UpdateExperienceLandingDetails = async (restaurantData) => {
console.log(restaurantData,'testing' )
  const data = new FormData();

  data.append("id", restaurantData.id);
  data.append("banner", restaurantData.banerImage);
  data.append("description", restaurantData.description);
  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);
  const response = await axiosInstance.request({
    url: "addExperienceBanner",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};


export const AddExperienceDetails = async (restaurantData) => {

  const data = new FormData();
  data.append("cardImage", restaurantData.cardImage);
  data.append("banner", restaurantData.banner);
  data.append("slider", JSON.stringify(restaurantData.slider));
  data.append("page", JSON.stringify(restaurantData.listData));
  data.append("shortdescription", restaurantData.shortdescription);
  data.append("category", restaurantData.category);
  data.append("description", restaurantData.description);
  data.append("title", restaurantData.title);
  data.append("locationId", restaurantData.locationId);
  data.append("locationtype", restaurantData.locationtype);
  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);
  const response = await axiosInstance.request({
    url: "addExperience",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const UpdateExperience = async (restaurantData) => {

  const data = new FormData();

  data.append("id", restaurantData.id);
  data.append("cardImage", restaurantData.cardImage);
  data.append("banner", restaurantData.banner);
  data.append("slider", JSON.stringify(restaurantData.slider));
  data.append("shortdescription", restaurantData.shortdescription);
  data.append("category", restaurantData.category);
  data.append("description", restaurantData.description);
  data.append("title", restaurantData.title);
  data.append("locationId", restaurantData.locationId);
  data.append("locationtype", restaurantData.locationtype);
  data.append("page", JSON.stringify(restaurantData.listData));
  data.append("meta_title", restaurantData.metaTitle);
  data.append("meta_description", restaurantData.metaDescription);
  data.append("meta_tag", restaurantData.metaTag);
  const response = await axiosInstance.request({
    url: "UpdateExperience",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const ShowExperienceList = async () => {
  const response = await axiosInstance.request({
    url: 'showExperiences',
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const FindExperienceList = async (id) => {
  const response = await axiosInstance.request({
    url: `FindExperience/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const AddGettingDetails= async (gettingData) => {
  const data = new FormData();
  data.append("retreat", gettingData.retreatId);
  data.append("banner", gettingData.imgHome);
  data.append("first_title", gettingData.firstTitle);
  data.append("second_title", gettingData.secondTitle);
  data.append("third_title", gettingData.thirdTitle);
  data.append("fourth_title", gettingData.fourthTitle);
  data.append("first_content", gettingData.firstDescription);
  data.append("second_content", gettingData.secondDescription);
  data.append("third_content", gettingData.thirdDescription);
  data.append("fourth_content", gettingData.fourthDescription);
  const response = await axiosInstance.request({
    url: "addgettingDetails",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const UpdateGettingDetails= async (gettingData) => {
  const data = new FormData();
  data.append("id", gettingData.id);
  data.append("retreat", gettingData.retreatId);
  data.append("banner", gettingData.imgHome);
  data.append("first_title", gettingData.firstTitle);
  data.append("second_title", gettingData.secondTitle);
  data.append("third_title", gettingData.thirdTitle);
  data.append("fourth_title", gettingData.fourthTitle);
  data.append("first_content", gettingData.firstDescription);
  data.append("second_content", gettingData.secondDescription);
  data.append("third_content", gettingData.thirdDescription);
  data.append("fourth_content", gettingData.fourthDescription);
  const response = await axiosInstance.request({
    url: "updategettingDetails",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const ShowGettingList = async () => {
  const response = await axiosInstance.request({
    url: 'gettingDetails',
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};


export const FindGettingList = async (id) => {
  const response = await axiosInstance.request({
    url: `findgettingDetails/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteGettingHere = async (id) => {
  const response = await axiosInstance.request({
    url: `DeletegettingDetails/${id}`,
    method: "DELETE",
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const findCelebrationType = async (id) => {

  const response = await axiosInstance.request({
    url: `id_celebration_type/${id}`,
    method: "GET",

    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
